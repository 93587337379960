import React from 'react';
import { graphql } from 'gatsby';

import AppConstants from '../app/constants';
import CardTable from '../components/Table/CardTable';
import GlobalActivity from '../components/GlobalActivity/GlobalActivity';
import MaleFemaleData from '../components/MaleFemaleData/MaleFemaleData';
import PageTitle from '../components/layout/PageTitle/PageTitle';
import SEO from '../components/seo';

import departmentsReaCapacity from '../data/dep_rea_capa.json';

const DepartmentPageTemplate = ({ data, pageContext, franceStats }) => {
  const { depId } = pageContext;

  const allSexData = data.allSex.nodes.filter((item) => item.sexe === AppConstants.SEX.all);
  const maleData = data.allSex.nodes.filter((item) => item.sexe === AppConstants.SEX.male);
  const femaleData = data.allSex.nodes.filter((item) => item.sexe === AppConstants.SEX.female);

  console.log('maleData', maleData);
  console.log('femaleData', femaleData);
  console.log('allSexData', allSexData);

  const maleGlobalStats = {};
  const femaleGlobalStats = {};

  const todayData = {
    dc: parseInt(allSexData[allSexData.length - 1].dc, 10),
    hosp: parseInt(allSexData[allSexData.length - 1].hosp, 10),
    rea: parseInt(allSexData[allSexData.length - 1].rea, 10),
    rad: parseInt(allSexData[allSexData.length - 1].rad, 10),
    total: parseInt(allSexData[allSexData.length - 1].dc, 10) + parseInt(allSexData[allSexData.length - 1].hosp, 10) + parseInt(allSexData[allSexData.length - 1].rea, 10) + parseInt(allSexData[allSexData.length - 1].rad, 10),
  };

  const yesterdayData = {
    dc: parseInt(allSexData[allSexData.length - 2].dc, 10),
    hosp: parseInt(allSexData[allSexData.length - 2].hosp, 10),
    rea: parseInt(allSexData[allSexData.length - 2].rea, 10),
    rad: parseInt(allSexData[allSexData.length - 2].rad, 10),
  };

  const allDates = allSexData.map((item) => item.jour);
  const lastDate = allDates[allDates.length - 1];
  const allSexDataByDate = {};

  const reaCapacity = departmentsReaCapacity[depId].rea_capacity;

  const dailyData = {};

  allSexData.forEach((day, index) => {
    const dayData = {
      ...day,
      dc: parseInt(day.dc, 10),
      hosp: parseInt(day.hosp, 10),
      rad: parseInt(day.rad, 10),
      rea: parseInt(day.rea, 10),
    };

    allSexDataByDate[day.jour] = dayData;
    allSexDataByDate[day.jour].total = dayData.dc + dayData.hosp + dayData.rea + dayData.rad;

    if (index > 0) {
      dailyData[day.jour] = {
        dc: allSexDataByDate[day.jour].dc - allSexDataByDate[allSexData[index - 1].jour].dc,
        hosp: allSexDataByDate[day.jour].hosp - allSexDataByDate[allSexData[index - 1].jour].hosp,
        rea: allSexDataByDate[day.jour].rea - allSexDataByDate[allSexData[index - 1].jour].rea,
        rad: allSexDataByDate[day.jour].rad - allSexDataByDate[allSexData[index - 1].jour].rad,
        total: allSexDataByDate[day.jour].total - allSexDataByDate[allSexData[index - 1].jour].total,
      };
    }
  });

  console.log('maleGlobalStats', maleGlobalStats);
  console.log('femaleGlobalStats', femaleGlobalStats);

  // Get male stats
  maleGlobalStats.dc = parseInt(maleData[maleData.length - 1].dc, 10);
  maleGlobalStats.hosp = parseInt(maleData[maleData.length - 1].hosp, 10);
  maleGlobalStats.rea =  parseInt(maleData[maleData.length - 1].rea, 10);
  maleGlobalStats.rad = parseInt(maleData[maleData.length - 1].rad, 10);
  maleGlobalStats.total = maleGlobalStats.dc + maleGlobalStats.hosp + maleGlobalStats.rea + maleGlobalStats.rad;
  maleGlobalStats.tauxHosp = Math.round((maleGlobalStats.hosp / todayData.hosp) * 100);
  maleGlobalStats.tauxDc = Math.round((maleGlobalStats.dc / todayData.dc) * 100);
  maleGlobalStats.tauxRea = Math.round((maleGlobalStats.rea / todayData.rea) * 100);
  maleGlobalStats.tauxRad = Math.round((maleGlobalStats.rad / todayData.rad) * 100);
  maleGlobalStats.tauxTotal = Math.round((maleGlobalStats.total / todayData.total) * 100);

  // Get female stats
  femaleGlobalStats.dc = parseInt(femaleData[femaleData.length - 1].dc, 10);
  femaleGlobalStats.hosp = parseInt(femaleData[femaleData.length - 1].hosp, 10);
  femaleGlobalStats.rea =  parseInt(femaleData[femaleData.length - 1].rea, 10);
  femaleGlobalStats.rad = parseInt(femaleData[femaleData.length - 1].rad, 10);
  femaleGlobalStats.total = femaleGlobalStats.dc + femaleGlobalStats.hosp + femaleGlobalStats.rea + femaleGlobalStats.rad;
  femaleGlobalStats.tauxHosp = Math.round((femaleGlobalStats.hosp / todayData.hosp) * 100);
  femaleGlobalStats.tauxDc = Math.round((femaleGlobalStats.dc / todayData.dc) * 100);
  femaleGlobalStats.tauxRea = Math.round((femaleGlobalStats.rea / todayData.rea) * 100);
  femaleGlobalStats.tauxRad = Math.round((femaleGlobalStats.rad / todayData.rad) * 100);
  femaleGlobalStats.tauxTotal = Math.round((femaleGlobalStats.total / todayData.total) * 100);

  const globalStats = {
    active: todayData.hosp + todayData.rea,
    cases: todayData.dc + todayData.hosp + todayData.rea + todayData.rad,
    // casesPerOneMillion
    // country
    critical: todayData.rea,
    deaths: todayData.dc,
    // deathsPerOneMillion
    recovered: todayData.rad,
    todayCases: (todayData.dc + todayData.hosp + todayData.rea + todayData.rad) - (yesterdayData.dc + yesterdayData.hosp + yesterdayData.rea + yesterdayData.rad),
    todayDeaths: todayData.dc - yesterdayData.dc,
  };

  return (
    <>
      <SEO title={franceStats.departments[depId].nom} />

      <PageTitle title={`Département ${franceStats.departments[depId].nom}`} />

      <GlobalActivity
        data={{
          allDates,
          lastDate,
          dataByDate: allSexDataByDate,
          reaCapacity,
          daily: dailyData,
        }}
        globalStats={globalStats}
        cumulChartItem={['total', 'dc', 'hosp', 'rea', 'rad']}
        dailyChartItems={['dc', 'hosp', 'rea', 'rad']}
      />

      <MaleFemaleData male={maleGlobalStats} female={femaleGlobalStats} />

      <CardTable
        headers={['Jour', 'Total', 'Décès', 'Hosp', 'Réa', 'Rétablis']}
        dataKeys={['jour', 'total', 'dc', 'hosp', 'rea', 'rad']}
        data={Object.values(allSexDataByDate).reverse()}
      />
    </>
  );
};

export default DepartmentPageTemplate;

export const query = graphql`
  query DepartmentPageQuery($depId: String!) {
    allSex: allDataCsv(filter: {dep: {eq: $depId}}) {
      nodes {
        dc
        hosp
        dep
        jour
        rad
        rea
        sexe
      }
    }
  }
`;