import PropTypes from 'prop-types';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Pie } from 'react-chartjs-2';

import AppConstants from '../../app/constants';

const MaleFemaleData = ({ male, female }) => {
  const [isMobile, setIsMobile] = React.useState(false);
  const [isready, setIsReady] = React.useState(false);

  React.useEffect(() => {
    setIsMobile(window.matchMedia('only screen and (max-width: 760px)').matches);
    setIsReady(true);
  }, []);

  const pieTotal = {
    labels: ['Hommes', 'Femmes'],
    datasets: [{
      data: [male.tauxTotal, female.tauxTotal],
      backgroundColor: [AppConstants.SEX_COLORS.male, AppConstants.SEX_COLORS.female],
      hoverBackgroundColor: [AppConstants.SEX_COLORS.male, AppConstants.SEX_COLORS.female],
    }]
  };

  /* const pieHosp = {
    labels: ['Hommes', 'Femmes'],
    datasets: [{
      data: [male.tauxHosp, female.tauxHosp],
      backgroundColor: [AppConstants.SEX_COLORS.male, AppConstants.SEX_COLORS.female],
      hoverBackgroundColor: [AppConstants.SEX_COLORS.male, AppConstants.SEX_COLORS.female],
    }]
  }; */

  const pieDc = {
    labels: ['Hommes', 'Femmes'],
    datasets: [{
      data: [male.tauxDc, female.tauxDc],
      backgroundColor: [AppConstants.SEX_COLORS.male, AppConstants.SEX_COLORS.female],
      hoverBackgroundColor: [AppConstants.SEX_COLORS.male, AppConstants.SEX_COLORS.female],
    }]
  };

  const pieRea = {
    labels: ['Hommes', 'Femmes'],
    datasets: [{
      data: [male.tauxRea, female.tauxRea],
      backgroundColor: [AppConstants.SEX_COLORS.male, AppConstants.SEX_COLORS.female],
      hoverBackgroundColor: [AppConstants.SEX_COLORS.male, AppConstants.SEX_COLORS.female],
    }]
  };

  const pieRad = {
    labels: ['Hommes', 'Femmes'],
    datasets: [{
      data: [male.tauxRad, female.tauxRad],
      backgroundColor: [AppConstants.SEX_COLORS.male, AppConstants.SEX_COLORS.female],
      hoverBackgroundColor: [AppConstants.SEX_COLORS.male, AppConstants.SEX_COLORS.female],
    }]
  };

  if (!isready) {
    return null;
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title>
          Répartition hommes/femmes
        </Card.Title>
      </Card.Header>

      <Card.Body>
        <Row>
          <Col xs={6}>
            <Pie data={pieTotal} options={{ title: { text: 'Cas confirmés', display: true }}} height={isMobile ? 400 : 156} />
          </Col>

          <Col xs={6}>
            <Pie data={pieDc} options={{ title: { text: 'Décès', display: true }}} height={isMobile ? 400 : 156} />
          </Col>
        </Row>

        <Row>
          <Col xs={6}>
            <Pie data={pieRea} options={{ title: { text: 'Réanimation', display: true }}} height={isMobile ? 400 : 156} />
          </Col>

          <Col xs={6}>
            <Pie data={pieRad} options={{ title: { text: 'Rétablissement', display: true }}} height={isMobile ? 400 : 156} />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

MaleFemaleData.propTypes = {
  male: PropTypes.object.isRequired,
  female: PropTypes.object.isRequired,
};

export default MaleFemaleData;
